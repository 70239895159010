<template>
	<div>
		<div class="container mb-4">
			<!-- <h1 class="text-center">{{title}}</h1> -->

			<b-tabs v-model="tabIndex" @input="tabChanged">
				<b-tab title="Pagina beheer">
					<!-- pages -->
					<button class="btn btn-outline-secondary my-3" @click="createPage()">
						<v-icon name="plus-square" scale="2" class="align-middle mr-2" />pagina toevoegen
					</button>
					<button class="btn btn-outline-secondary my-3 float-right" @click="logout()">
						<v-icon name="times" scale="2" class="align-middle mr-2" />uitloggen
					</button>
					<table class="table table-hover table-sm">
						<thead>
							<!-- <th class="tdw">pagina</th> -->
							<th>titel</th>
							<th>pad / url</th>
							<th>menu-item</th>
							<th>
								<a v-b-tooltip.v-danger title="Nivo naar links">
									<v-icon name="caret-left" :scale="iconScale" />
								</a>
								<a v-b-tooltip.v-danger title="Nivo naar rechts">
									<v-icon name="caret-right" :scale="iconScale" />
								</a>
								<a v-b-tooltip.v-danger title="Naar boven verplaatsen">
									<v-icon name="caret-up" :scale="iconScale" />
								</a>
								<span class="rank" v-b-tooltip.v-danger title="Volgorde">#</span>
								<a v-b-tooltip.v-danger title="Naar beneden verplaatsen">
									<v-icon name="caret-down" :scale="iconScale" />
								</a>
								<a v-b-tooltip.v-danger title="Aanpassen pagina">
									<v-icon name="edit" />
								</a>
								<a v-b-tooltip.v-danger title="Pagina verwijderen">
									<v-icon name="trash-alt" />
								</a>
								<!-- <a v-b-tooltip.v-danger title="SEO">
									<v-icon name="search-plus" />
								</a>-->
							</th>
						</thead>
						<tbody>
							<tr v-for="p in pages" :key="p.id" class="border-top border-bottom">
								<!-- <td class="pr-4" :class="{'l1': p.level==1, 'pl-4': p.level==2, 'pl-5': p.level>=3}">
								<div>{{ p.title }}</div>
							</td>-->
								<td
									class="pr-4"
									:class="{ l1: p.level == 1, 'pl-4': p.level == 2, 'pl-5': p.level >= 3 }"
								>
									<editable :item="p.title" @updated="updatePage(p, 'title', $event)"></editable>
								</td>
								<td class="pr-4">
									<editable :item="p.path" @updated="updatePage(p, 'path', $event)"></editable>
								</td>
								<td class="text-center">
									<input
										type="checkbox"
										v-model="p.menu_item"
										@change="updatePage(p, 'menu_item', p.menu_item)"
									/>
								</td>
								<td class="pr-4">
									<a @click="left(p)">
										<v-icon name="caret-left" :scale="iconScale" />
									</a>
									<a @click="right(p)">
										<v-icon name="caret-right" :scale="iconScale" />
									</a>
									<a @click="up(p)">
										<v-icon name="caret-up" :scale="iconScale" />
									</a>
									<span class="rank">{{ p.rank }}</span>
									<a @click="down(p)">
										<v-icon name="caret-down" :scale="iconScale" />
									</a>
									<a @click="editPage(p)">
										<v-icon name="edit" />
									</a>
									<a @click="deletePage(p)">
										<v-icon name="trash-alt" />
									</a>
									<!-- <a @click="editPage(p)">
									<v-icon name="search-plus" />
								</a>-->
								</td>
							</tr>
						</tbody>
					</table>
				</b-tab>

				<b-tab title="Pagina inhoud (home)">
					<!-- header -->
					<div class="row mt-4 py-3 border rounded shadow">
						<div class="col-1">header:</div>
						<div class="col-3">
							<editable
								:item="selectedPage.header_title"
								@updated="updatePage(selectedPage, 'header_title', $event)"
							></editable>
						</div>
						<div class="col">
							<editable
								:item="selectedPage.header_quote"
								@updated="updatePage(selectedPage, 'header_quote', $event)"
							></editable>
						</div>
						<div class="col">
							<editable
								:item="selectedPage.header_image"
								@updated="updatePage(selectedPage, 'header_image', $event)"
							></editable>
						</div>
					</div>

					<!-- intro -->
					<div class="row mt-4 py-3 border rounded shadow">
						<div class="col-1">intro:</div>
						<div class="col-3">
							<editable
								:item="selectedPage.intro_title"
								@updated="updatePage(selectedPage, 'intro_title', $event)"
							></editable>
						</div>
						<div class="col">
							<editable
								:item="selectedPage.intro_content"
								@updated="updatePage(selectedPage, 'intro_content', $event)"
							></editable>
						</div>
					</div>

					<!-- tiles -->
					<div class="row mt-4 border rounded shadow">
						<div class="col">
							<table class="table bordered">
								<thead>
									<tr>
										<th>tegel</th>
										<th>titel</th>
										<th>inhoud</th>
										<th>achtergrondkleur</th>
										<th>afbeelding</th>
										<th>link</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="tile in tiles" :key="tile.id">
										<td class="w-auto">{{ tile.rank }}</td>
										<td class="w-25">
											<editable
												:item="tile.title"
												@updated="updateTile(tile, 'title', $event)"
											></editable>
										</td>
										<td>
											<editable
												:item="tile.content"
												@updated="updateTile(tile, 'content', $event)"
											></editable>
										</td>
										<td>
											<editable
												:item="tile.bgcolor"
												@updated="updateTile(tile, 'bgcolor', $event)"
											></editable>
										</td>
										<td>
											<editable
												:item="tile.image"
												@updated="updateTile(tile, 'image', $event)"
											></editable>
										</td>
										<td>
											<editable
												:item="tile.link"
												@updated="updateTile(tile, 'link', $event)"
											></editable>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</b-tab>

				<b-tab title="Pagina inhoud (overig)">
					<div v-show="!isEditing">
						<!-- page selector -->
						<div class="row mt-4">
							<div class="col-md-1">pagina:</div>
							<div class="col-md-3">{{ this.selected == '' ? 'niet geselecteerd' : this.selected }}</div>
							<div class="col-md-3" v-if="selected">
								<button
									class="btn btn-outline-secondary btn-sm"
									v-bind:disabled="selected == ''"
									@click="createRow(selected)"
								>
									<v-icon name="plus-square" scale="2" class="align-middle mr-2" />regel toevoegen
								</button>
							</div>
							<br />
							<br />
						</div>

						<!-- header -->
						<div class="row mt-4 py-3 border rounded shadow" v-if="selected">
							<div class="col-1">header:</div>
							<div class="col-3">
								<editable
									:item="selectedPage.header_title"
									@updated="updatePage(selectedPage, 'header_title', $event)"
								></editable>
							</div>
							<div class="col">
								<editable
									:item="selectedPage.header_quote"
									@updated="updatePage(selectedPage, 'header_quote', $event)"
								></editable>
							</div>
							<div class="col">
								<editable
									:item="selectedPage.header_image"
									@updated="updatePage(selectedPage, 'header_image', $event)"
								></editable>
							</div>
						</div>

						<!-- intro -->
						<div class="row mt-4 py-3 border rounded shadow" v-if="selected">
							<div class="col-1">intro:</div>
							<div class="col-3">
								<editable
									:item="selectedPage.intro_title"
									@updated="updatePage(selectedPage, 'intro_title', $event)"
								></editable>
							</div>
							<div class="col">
								<editable
									:item="selectedPage.intro_content"
									@updated="updatePage(selectedPage, 'intro_content', $event)"
								></editable>
							</div>
						</div>

						<!-- avatar -->
						<div class="row mt-4 py-3 border rounded shadow" v-if="selected">
							<div class="col-1">citaat tekst:</div>
							<div class="col-3">
								<editable
									:item="selectedPage.avatar"
									@updated="updatePage(selectedPage, 'avatar', $event)"
								></editable>
							</div>
							<div class="col"></div>
						</div>

						<!-- rows -->
						<div class="row mt-4 border rounded shadow" v-for="(q, index) in quarks" :key="index">
							<!-- row properties -->
							<div class="col-4">
								<table class="table table-borderless table-sm my-3">
									<tbody>
										<!-- title -->
										<tr>
											<td>rij titel:</td>
											<td>
												<editable
													:item="q.title"
													@updated="updateRow(q, 'title', $event)"
												></editable>
											</td>
										</tr>

										<!-- rank -->
										<tr>
											<td>volgorde:</td>
											<td>
												<a
													@click="upRow(q)"
													v-b-tooltip.v-danger
													title="Naar boven verplaatsen"
												>
													<v-icon name="caret-up" :scale="iconScale" />
												</a>
												{{ q.rank }}
												<a
													@click="downRow(q)"
													v-b-tooltip.v-danger
													title="Naar beneden verplaatsen"
												>
													<v-icon name="caret-down" :scale="iconScale" />
												</a>
											</td>
										</tr>

										<!-- nr of columns -->
										<tr>
											<td>kolommen:</td>
											<td>
												<a @click="addToRowNrCols(q, -1)">
													<v-icon name="caret-left" :scale="iconScale" />
												</a>
												{{ q.nrCols }}
												<a @click="addToRowNrCols(q, 1)">
													<v-icon name="caret-right" :scale="iconScale" />
												</a>
												<editable
													:item="q.cols"
													@updated="updateRow(q, 'cols', $event)"
												></editable>
											</td>
										</tr>

										<!-- checkbox background color -->
										<tr>
											<td>achtergrondkleur</td>
											<td>
												<input
													type="checkbox"
													v-model="q.config"
													true-value="1"
													false-value="0"
													@change="bgToggle(q)"
												/>
											</td>
										</tr>

										<!-- delete row icon -->
										<tr>
											<td></td>
											<td>
												<a @click="deleteRow(index, q.id)">
													<v-icon name="trash-alt" scale="1.2" />
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!-- row content columns -->
							<div class="col">
								<table class="table table-bordered my-3" v-show="!isEditing">
									<tbody>
										<tr>
											<td>
												<div class="container">
													<div class="row">
														<div
															class="col border"
															v-for="n in q.nrCols"
															:key="n"
															@click.prevent="editQuark(n - 1, index)"
														>
															<div v-html="q.columns[n - 1]"></div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<!-- visible when editing -->
					<div v-show="isEditing">
						<!-- save close buttons -->
						<div class="row my-2">
							<div class="col-md-2">
								<button type="button" class="btn btn-outline-secondary" @click="closeEditor(true)">
									<v-icon name="save" scale="2" class="align-middle mr-3" />save
								</button>
							</div>
							<div class="col-md-2">
								<button type="button" class="btn btn-outline-secondary" @click="closeEditor(false)">
									<v-icon name="times" scale="2" class="align-middle mr-3" />cancel
								</button>
							</div>
						</div>

						<!-- editor window -->
						<div class="row">
							<div class="col-md-12">
								<vue-ckeditor v-model="editorData" :config="editorConfig" @blur="onBlur"></vue-ckeditor>
							</div>
						</div>
					</div>
				</b-tab>

				<b-tab title="File upload">
					<div class="row mt-4">
						<div class="col-10 offset-1 text-center border py-4">
							<h3>File upload</h3>
							<vue-dropzone
								ref="myVueDropzone"
								id="dropzone"
								:options="dropzoneOptions"
								:useCustomSlot="true"
							>
								<div class="dropzone-custom-content">
									<v-icon name="upload" scale="3" />
									<div>Sleep bestand hier naartoe</div>
									<div>...of klik om een bestand te selecteren</div>
								</div>
							</vue-dropzone>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-2 my-1" v-for="(upl, index) in uploads" :key="index">
							<div class="card h-100">
								<img :src="upl" class="card-img-top" />
								{{ upl.replace('../uploads/', '') }}
								<a class="mt-auto" @click="deleteFile(upl)">
									<v-icon name="trash-alt" />
								</a>
							</div>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>

		<b-modal id="modalLogin" hide-header hide-footer no-close-on-esc no-close-on-backdrop>
			<form v-on:submit.prevent="login">
				<h3 class="fg-red text-center mb-3">Login</h3>

				<div class="form-group row">
					<label for="inputEmail3a" class="col-md-3 col-form-label">Email</label>
					<div class="col">
						<input v-model="user.email" type="text" class="form-control" id="inputEmail3a" />
					</div>
				</div>
				<div class="form-group row">
					<label for="inputPassword3" class="col-md-3 col-form-label">Wachtwoord</label>
					<div class="col">
						<input v-model="user.password" type="password" class="form-control" id="inputPassword3" />
					</div>
				</div>
				<br />
				<button class="bg-apricot text-white col">Login</button>
				<!-- <p class="fg-red"> {{ user.loginOkay }}</p> -->
				<!-- v-show="loginOkay" Incorrect email/wachtwoord -->
			</form>
		</b-modal>
	</div>
</template>

<script>
import Vue from 'vue';

import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);

import { TabsPlugin } from 'bootstrap-vue';
Vue.use(TabsPlugin);

import { TooltipPlugin } from 'bootstrap-vue';
Vue.use(TooltipPlugin);

import { ModalPlugin } from 'bootstrap-vue';
Vue.use(ModalPlugin);

// import axios from '@/js/axios';
import axios from 'axios';
import utils from '@/js/utils';
import _ from 'lodash';

import editable from '@/components/Editable';
import VueCkeditor from 'vue-ckeditor2';

// fontawesome icons
import 'vue-awesome/icons/caret-up';
import 'vue-awesome/icons/caret-right';
import 'vue-awesome/icons/caret-down';
import 'vue-awesome/icons/caret-left';
import 'vue-awesome/icons/edit';
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/minus';
import 'vue-awesome/icons/plus-square';
import 'vue-awesome/icons/save';
import 'vue-awesome/icons/search-plus';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/trash-alt';
import 'vue-awesome/icons/upload';
import Icon from 'vue-awesome/components/Icon';

// file uploader
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
	data: () => ({
		BASE_URL: '/php/cms.php',
		TABLE_PAGES: '/pages/',
		TABLE_ROWS: '/rows/',
		TABLE_PRICES: '/prices/',

		UPLOADS_URL: '/php/get-uploads.php',
		uploads: [],

		tiles: [],

		isActive: true,
		isEditing: false,
		editColumn: 0,
		editRowIndex: -1,
		selected: '',
		selectedPage: {},

		pages: [],
		quarks: [],
		prices: [],

		editorData: '',
		editorConfig: {
			// toolbar: 'Standard',
			// toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'Sourcedialog']],
			// extraPlugins: 'sourcedialog',
			height: 300,
		},

		tabIndex: 0,
		iconScale: 1.8,

		// file upload tab
		dropzoneOptions: {
			addRemoveLinks: true,
			url: '/php/file-upload.php',
			maxFilesize: 8,
			// thumbnailWidth: 150,
			// headers: { 'My-Awesome-Header': 'header value' }
		},

		user: {
			loginOkay: false,
			email: '',
			password: '',
		},
		// EMAIL: '1',
		// PASSWORD: '2'
		// EMAIL: 'info@simonebusscher.nl',
		// PASSWORD: 'J239artHp5#',
	}),

	components: {
		VueCkeditor,
		editable,
		'v-icon': Icon,
		vueDropzone,
	},

	// watch: {
	// 	tabIndex(val) {
	// 		localStorage.setItem('last-tab', this.tabIndex);
	// 	}
	// },

	created() {
		console.clear();
		this.getPages();
		// this.getPrices();

		// this.tabIndex = parseInt(localStorage.getItem('last-tab'));
		// console.log(this.$router.options.routes);
	},

	mounted() {
		// console.log('mounted', this.$cookies.get('user_session'));
		if (this.$cookies.get('user_session') != '25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX') {
			this.$bvModal.show('modalLogin');
		}
	},

	methods: {
		// ----------------------------------------------------------------------------------------
		// editor
		// ----------------------------------------------------------------------------------------

		// edit quark row
		editQuark(column, index) {
			console.log('editQuark', column, index);
			this.editColumn = column;
			this.editRowIndex = index;
			this.editorData = this.quarks[index].columns[column];
			this.isEditing = true;
		},

		onBlur() {
			console.log('onBlur'); //, this.editorData);

			// fix reactivity of 2d array
			// this.quarks[this.editRowIndex].columns[this.editColumn] = this.editorData;

			// make copy of row
			let copy = this.quarks[this.editRowIndex];

			//update the value
			copy.columns[this.editColumn] = this.editorData;

			// update it in the array
			Vue.set(this.quarks, this.editRowIndex, copy);

			// update in database
			let row = this.quarks[this.editRowIndex];
			row.col1 = row.columns[0];
			row.col2 = row.columns[1];
			row.col3 = row.columns[2];
			row.col4 = row.columns[3];
			this.updateRow(row);
		},

		onFocus(editor) {
			console.log('onFocus');
			// console.log(editor)
		},

		closeEditor(e) {
			console.log('closeeditor', e);
			this.isEditing = false;
		},

		// ----------------------------------------------------------------------------------------
		// login
		// ----------------------------------------------------------------------------------------

		login() {
			// console.log('login');
			// console.log(this.hash(this.user.email) == '3234508646' && this.hash(this.user.password) == '1065538891');

			// if (this.user.email === this.EMAIL && this.user.password === this.PASSWORD) {
			if (this.hash(this.user.email) == '3234508646' && this.hash(this.user.password) == '1065538891') {
				this.loginOkay = true;
				console.log('login ok');
				this.$cookies.set('user_session', '25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX', 3600);
				this.$bvModal.hide('modalLogin');
			} else {
				this.loginOkay = false;
				console.log('login fail');
				this.$cookies.remove('user_session');
			}
		},

		logout() {
			this.$cookies.remove('user_session');
			this.$router.push('/');
		},

		hash(thing) {
			let hash, i, chr;
			hash = 0;
			if (thing.length === 0) return hash;
			for (i = 0; i < thing.length; i++) {
				chr = thing.charCodeAt(i);
				hash = (hash << 5) - hash + chr;
				//hash |= 0; // Convert to 32bit integer
			}
			return hash.toString();
		},

		// ----------------------------------------------------------------------------------------
		// tabs events
		// ----------------------------------------------------------------------------------------

		tabChanged(selectedTab) {
			if (selectedTab == 3) {
				this.getUploads();
			}
		},

		// ----------------------------------------------------------------------------------------
		// uploads
		// ----------------------------------------------------------------------------------------

		getUploads() {
			axios
				.get(this.UPLOADS_URL)
				.then((response) => {
					this.uploads = response.data;
					// console.log(this.uploads);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		deleteFile(file) {
			let url = '/php/delete-file.php?delete=' + file;
			axios
				.get(url)
				.then((response) => {
					console.log(response.data);
					this.getUploads();
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// ----------------------------------------------------------------------------------------
		// pages
		// ----------------------------------------------------------------------------------------

		// create page in database
		async createPage() {
			console.log('createPage');

			var newPage = {
				path: '<wijzigen>',
				title: '<wijzigen>',
				rank: this.pages.length,
			};

			let url = this.BASE_URL + this.TABLE_PAGES;
			await axios
				.post(url, newPage)
				.then((response) => {
					console.log(response);
					var result = response.data.success;
					if (result && result.code == 201) {
						console.log(result.code, result.status);
					}
					this.getPages();
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// read pages from database
		async getPages(rank) {
			// console.log('getPages');

			this.pages = [];
			let url = this.BASE_URL + this.TABLE_PAGES;
			const result = await axios.get(url);
			this.pages = result.data;
			utils.sortByRank(this.pages);
			// console.log('getpages', this.pages);

			if (rank) {
				this.rankPages();
			}
		},

		// update page into database
		async updatePage(page, col, newValue) {
			let url = this.BASE_URL + this.TABLE_PAGES + page.id;
			console.log('updatePage', url);

			if (col != undefined) {
				console.log(page, col, newValue);
				page[col] = newValue;
				console.log(page, col, newValue);
			}

			const result = await axios.put(url, page);
			console.log('update', result.data);
		},

		deletePage(page) {
			console.log('delete page', page);
			this.$dialog
				.confirm('Are you sure to delete this row?', {
					okText: 'OK',
					cancelText: 'Cancel',
				})
				.then((dialog) => {
					// delete row from database
					let url = this.BASE_URL + this.TABLE_PAGES + page.id;
					console.log(url);
					axios
						.delete(url)
						.then((response) => {
							if (response.data.success) {
								console.log('deleted');
								this.getPages(true);
							} else {
								console.log('Error', response.data.error.code, ':', response.data.error.status);
								// this.$toast.open('Regel verwijderd')
							}
						})
						.catch((error) => {
							console.log(error);
						});
				})
				.catch((dialog) => {
					console.log('Clicked on cancel');
				});
		},

		editPage(page) {
			console.log('page', page);
			this.selected = page.path;
			this.selectedPage = page;
			if (page.path != '') {
				this.getRows(page.path);
				this.tabIndex = 2;
			} else {
				this.getTiles();
				this.tabIndex = 1;
			}
		},

		async rankPages() {
			console.log('rankPages');
			for (let i = 0; i < this.pages.length; i++) {
				let p = this.pages[i];
				p.rank = i.toString();
				await this.updatePage(p);
			}
		},

		async addToPageRank(page, offset) {
			// add offset to string
			let next = (parseInt(page.rank) + offset).toString();
			let nextPage = _.find(this.pages, ['rank', next]);
			console.log(page.path, '<=>', nextPage.path);

			let current = page.rank;
			page.rank = next;
			nextPage.rank = current;

			utils.sortByRank(this.pages);
			await this.updatePage(page);
			await this.updatePage(nextPage);
		},
		left(page) {
			if (page.level > 1) {
				page.level--;
				this.updatePage(page);
			}
		},
		right(page) {
			if (page.level <= 3) {
				page.level++;
				this.updatePage(page);
			}
		},
		up(page) {
			if (page.rank > 1) {
				this.addToPageRank(page, -1);
			}
		},
		down(page) {
			if (page.rank < this.pages.length) {
				this.addToPageRank(page, 1);
			}
		},

		// ----------------------------------------------------------------------------------------
		// tiles
		// ----------------------------------------------------------------------------------------

		getTiles(row) {
			this.tiles = [];
			let url = this.BASE_URL + '/tiles/';

			axios
				.get(url)
				.then((response) => {
					console.log(response.data);
					this.tiles = response.data;

					//utils.sortByRank(this.pages);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		updateTile(row, col, newVal) {
			var url = this.BASE_URL + '/tiles/' + row.id;
			row[col] = newVal;
			console.log(row, newVal);
			console.log(url);

			const copiedRow = JSON.parse(JSON.stringify(row));

			delete copiedRow.nrCols;
			delete copiedRow.columns;

			axios
				.put(url, copiedRow)
				.then((response) => {
					console.log(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// ----------------------------------------------------------------------------------------
		// content rows
		// ----------------------------------------------------------------------------------------

		// get id from given page path
		getIdFromPath(path) {
			let id = -1;
			for (let i = 0; i < this.pages.length; i++) {
				let pagePath = this.pages[i].path;
				// if (pagePath == 'home') pagePath = '';
				if (path == pagePath) {
					id = this.pages[i].id;
					break;
				}
			}

			return id;
		},

		// add new row to database
		async createRow(selected) {
			console.clear();
			console.log('create row', selected);

			let id = this.getIdFromPath(selected);
			let url = this.BASE_URL + this.TABLE_ROWS;
			// console.log(url);

			var newRow = {
				page_id: id,
				rank: this.quarks.length,
			};

			await axios
				.post(url, newRow)
				.then((response) => {
					console.log(response);
					var result = response.data.success;
					if (result && result.code == 201) {
						// console.log(result.code, result.status);
						this.getRows(selected);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// get rows from database
		async getRows(selectedPath) {
			// console.log('getRows', selectedPath);

			this.quarks = [];

			let id = this.getIdFromPath(selectedPath);
			let url = this.BASE_URL + this.TABLE_ROWS + 'page_id/' + id;

			const result = await axios.get(url);
			if (!result.error) {
				this.quarks = result.data;
				if (this.quarks.length > 0) {
					for (let i = 0; i < this.quarks.length; i++) {
						let q = this.quarks[i];

						if (q.col1 == null || q.col1 == '') {
							q.col1 = 'Lorem ipsum';
						}

						let arr = q.cols.split(';');
						q.nrCols = arr.length;
						q.columns = [];
						q.columns.push(q.col1);
						q.columns.push(q.col2);
						q.columns.push(q.col3);
						q.columns.push(q.col4);

						// check for image
						for (let i = 0; i < q.columns.length; i++) {
							// console.log('el', el);
							let el = q.columns[i];
							if (el != null) {
								if (el.indexOf('<img') != -1) {
									// console.log('img-fluid1', q.columns[i]);
									let a = utils.strip_tags(el, '<a>');
									let img = utils.strip_tags(el, '<img>');

									// keep link intact
									if (a.indexOf('<a href') == -1) {
										q.columns[i] = utils.img_fluid(img);
									} else {
										q.columns[i] = a.replace('</a>', utils.img_fluid(img) + '</a>');
									}
									// console.log('img-fluid2', q.columns[i]);
								}
							}
						}
					}

					// console.log('getRows', this.quarks);

					utils.sortByRank(this.quarks);
					await this.rankRows(this.quarks);
				}
			} else {
				console.log('Error', response.data.error.code, ':', response.data.error.status);
				// this.$toast.open('Regel verwijderd')
			}
		},

		// update row in database
		async updateRow(row, col, newVal) {
			console.log('updateRow');

			// var config = {
			// 	headers: {
			// 		'X-HTTP-Method-Override': 'PUT'
			// 	}
			// };
			// .put(url, row, config)

			var url = this.BASE_URL + this.TABLE_ROWS + row.id;

			if (col != undefined && newVal != undefined) {
				row[col] = newVal;
			}

			// delete added rows via copy
			const copiedRow = JSON.parse(JSON.stringify(row));
			delete copiedRow.nrCols;
			delete copiedRow.columns;

			// update in database
			await axios
				.put(url, copiedRow)
				.then((response) => {
					console.log('updateRow', response.data);
					//this.getRows(this.selected);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// delete row from database
		async deleteRow(index, id) {
			this.$dialog
				.confirm('Are you sure to delete this row?', {
					okText: 'OK',
					cancelText: 'Cancel',
				})
				.then((dialog) => {
					// delete row from database
					let url = this.BASE_URL + this.TABLE_ROWS + id;
					console.log(url);

					axios
						.delete(url)
						.then((response) => {
							if (response.data.success) {
								this.getRows(this.selected);
							} else {
								console.log('Error', response.data.error.code, ':', response.data.error.status);
								// this.$toast.open('Regel verwijderd')
							}
						})
						.catch((error) => {
							console.log(error);
						});
				})
				.catch((dialog) => {
					console.log('Clicked on cancel');
				});
		},

		async rankRows() {
			console.log('rankRows');
			for (let i = 0; i < this.quarks.length; i++) {
				let q = this.quarks[i];
				q.rank = i.toString();
				await this.updateRow(q);
			}
		},

		async addToRowRank(row, offset) {
			// add offset to string
			let next = (parseInt(row.rank) + offset).toString();
			let nextRow = _.find(this.quarks, ['rank', next]);

			let current = row.rank;
			row.rank = next;
			nextRow.rank = current;

			utils.sortByRank(this.quarks);
			await this.updateRow(row);
			await this.updateRow(nextRow);
		},
		upRow(row) {
			if (row.rank > 0) {
				this.addToRowRank(row, -1);
			}
		},
		downRow(row) {
			if (row.rank < this.quarks.length - 1) {
				this.addToRowRank(row, 1);
			}
		},
		addToRowNrCols(row, offset) {
			if ((offset < 0 && row.nrCols > 1) || (offset > 0 && row.nrCols < 4)) {
				row.nrCols += offset;
				let colW = (12 / row.nrCols).toString();
				row.cols = colW;
				for (let i = 0; i < row.nrCols - 1; i++) row.cols += ';' + colW;
				this.updateRow(row);
			}
		},
		bgToggle(row) {
			this.updateRow(row);
		},
	},
};
</script>

<style scoped>
/* tr:nth-child(even) {
	background-color: #f2f2f2;
} */

.fa-icon {
	margin: 0 8px;
	cursor: pointer;
}

.l1 {
	text-transform: uppercase;
}

.tdp {
	padding: 0 10px;
}

.dg-btn {
	border-width: 1px;
	font-weight: normal;
}

.img-icon {
	height: 10px;
}

.rank {
	display: inline-block;
	width: 20px;
	text-align: center;
}
</style>
